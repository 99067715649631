@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

.navbar {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    align-items: center;
    background-color: #060b26;
    z-index: 10000;
}

.navbar-container {
    height: 80px;
    max-width: 1500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-bars a {
    color: aliceblue;
}

.menu-bars {
    padding: 25px;
}

.navbar-loco {
    display: flex;
    margin-left: 20px;
    justify-self: flex-start;
    align-items: center;
}

.navbar-loco a {
    color: aliceblue;
    cursor: pointer;
    text-decoration: none; 
    font-size: 2rem;
    font-family: 'Krona One', sans-serif;
}