@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
.sidebar {
    height: 100vh;
    width: 250px;
    top: 0;
    left: -100%;
    display: -webkit-flex;
    display: flex;
    position: fixed;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #2F4050;
    transition: 850ms;
    z-index: 1100;
}

@media screen and (max-width: 768px) {
    .sidebar {
        width: 100vw;
        left: -110%;
    }
}

.sidebar.active {
    left: 0;
    transition: 350ms;
}

.sidebar-item {
    display: -webkit-flex;
    display: flex;
    list-style: none;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.sidebar-item a {
    height: 60px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin: 0;
    text-decoration: none;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: aliceblue;
    transition: all .3s ease-out;
}

.sidebar-item a:hover {
    background-color: #293846;
    transition: all .3s ease-out;
}

.sidebar-list {
    height: auto;
    width: 100%;
    padding: 0;
}

.sidebar-toggle {
    height: 80px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    background-color: #060b26;
    padding: 25px;
}

.sidebar-toggle a {
    color: aliceblue;
}

.sidebar-item a .sidebar-item-icon {
    -webkit-flex: 30% 1;
            flex: 30% 1;
    display: grid;
    place-items: center;
}

.sidebar-item a .sidebar-item-title {
    -webkit-flex: 70% 1;
            flex: 70% 1;
}

.sidebar-item.active {
    background-color: #293846;
}

.navbar {
    height: 80px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    position: fixed;
    top: 0;
    -webkit-align-items: center;
            align-items: center;
    background-color: #060b26;
    z-index: 10000;
}

.navbar-container {
    height: 80px;
    max-width: 1500px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.menu-bars a {
    color: aliceblue;
}

.menu-bars {
    padding: 25px;
}

.navbar-loco {
    display: -webkit-flex;
    display: flex;
    margin-left: 20px;
    justify-self: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.navbar-loco a {
    color: aliceblue;
    cursor: pointer;
    text-decoration: none; 
    font-size: 2rem;
    font-family: 'Krona One', sans-serif;
}
.leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.leaflet-container {
    width: 100%;
    height: 100vh;
}

.map-container {
    width: 80%;
    height: 100vh;
    margin: auto;
}

.login-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}


@-webkit-keyframes moveToLeft {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes moveToLeft {
	from { opacity: 0; }
	to { opacity: 1; }
}

@-webkit-keyframes moveToRight { 
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes moveToRight { 
	from { opacity: 0; }
	to { opacity: 1; }
}

.page-moveToLeft {
	-webkit-animation: moveToLeft .5s ease both;
	        animation: moveToLeft .5s ease both;
    overflow: hidden;
}

.page-moveToRight {
	-webkit-animation: moveToRight .5s ease both;
	        animation: moveToRight .5s ease both;
    overflow: hidden;
}

.back-bar {
	height: 100%;
	width: 100px;
	left: 0;
	position: fixed;
	background-color: red;
}
.wrapper {
  padding: 20px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;  
}

.App {
  height: 100vh;
  width: 100vw;
}

body {
  overflow-x: hidden;
  background-color: #f7f7f7;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0,0,0,0.4);
}

