.sidebar {
    height: 100vh;
    width: 250px;
    top: 0;
    left: -100%;
    display: flex;
    position: fixed;
    justify-content: center;
    background-color: #2F4050;
    transition: 850ms;
    z-index: 1100;
}

@media screen and (max-width: 768px) {
    .sidebar {
        width: 100vw;
        left: -110%;
    }
}

.sidebar.active {
    left: 0;
    transition: 350ms;
}

.sidebar-item {
    display: flex;
    list-style: none;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-item a {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    transition: all .3s ease-out;
}

.sidebar-item a:hover {
    background-color: #293846;
    transition: all .3s ease-out;
}

.sidebar-list {
    height: auto;
    width: 100%;
    padding: 0;
}

.sidebar-toggle {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #060b26;
    padding: 25px;
}

.sidebar-toggle a {
    color: aliceblue;
}

.sidebar-item a .sidebar-item-icon {
    flex: 30%;
    display: grid;
    place-items: center;
}

.sidebar-item a .sidebar-item-title {
    flex: 70%;
}

.sidebar-item.active {
    background-color: #293846;
}
