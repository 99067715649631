@keyframes moveToLeft {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes moveToRight { 
	from { opacity: 0; }
	to { opacity: 1; }
}

.page-moveToLeft {
	animation: moveToLeft .5s ease both;
    overflow: hidden;
}

.page-moveToRight {
	animation: moveToRight .5s ease both;
    overflow: hidden;
}

.back-bar {
	height: 100%;
	width: 100px;
	left: 0;
	position: fixed;
	background-color: red;
}