.wrapper {
  padding: 20px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;  
}

.App {
  height: 100vh;
  width: 100vw;
}

body {
  overflow-x: hidden;
  background-color: #f7f7f7;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0,0,0,0.4);
}
